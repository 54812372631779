.selectWrapper.darkTheme,
.selectWrapper{
    background-color: #ededed;
}
.selectWrapper.selected.darkTheme {
    background-color: #2e2e2e;
}
.smaller{
    font-size: 1rem;
    height: 30px;
    max-height: 30px;
    min-height: 30px;
    padding-top: 5px ;
    padding-bottom: 5px ;
}