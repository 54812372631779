.eamInput.darkTheme {}

.eamInput {
    margin: 0;
    padding: 0;
}

.eamInputTooltip {
    display: none;
}

.eamInputTooltip.errorInput {
    display: block;
}

.eamInput :global(.ant-input-number-handler-wrap) {
    display: none;
}

.eamInput.darkTheme :global(.ant-input-number):hover,
.eamInput.darkTheme :global(.ant-input-number-focused) {
    -webkit-box-shadow: 0 0 .2em 2px #444444a8;
    box-shadow: 0 0 .2em 2px #444444a8;
}

.eamInput.darkTheme.errorInput :global(.ant-input-number):hover,
.eamInput.darkTheme.errorInput :global(.ant-input-number-focused) {
    -webkit-box-shadow: 0 0 .2em 2px #b4363688;
    box-shadow: 0 0 .2em 2px #b4363688;
}

.eamInput.darkTheme :global(.ant-input-number) {
    border-color: #333333;
}

.eamInput.darkTheme.errorInput :global(.ant-input-number) {
    border-color: #b43636;
    color: red;
}

.eamInput :global(.ant-input-number), .eamInput :global(.ant-input-number-input) {
    background-color: inherit;
}