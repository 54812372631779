body {
    margin: 0;
}

.bold-large {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-style: normal;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.normal-font {
    min-width: 30rem;
    font-size: 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    @extend .normal-font
}

* {
    scrollbar-width: thin;
    outline: none;
}

*::-webkit-scrollbar {
    width: 1em;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

body {
    background-color: #e6e4e4;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ant-popover-arrow {
    border-right-color: #292929!important;
    border-bottom-color: #292929!important;
}

.ant-popover-inner,
.ant-popover-message,
.ant-popover-message>.anticon {
    background-color: #292929;
    color: #fff;
}
.ant-popover-buttons > .ant-btn {
    color: white;
}

.ant-btn {
    background-color: inherit;
    color: #000;
    border-color: #2e2e2e;
}

.ant-btn:focus,
.ant-btn:hover {
    background-color: #2e2e2e;
    color: #fff;
    border-color: #666;
    box-shadow: 0 0 2px .1px #666;
}

.ant-message-info .anticon,
.ant-message-success .anticon,
.ant-message-error .anticon {
    background-color: #2e2e2e;
    color: #fff;
    border-radius: 2em;
    border-width: .1em;
    border-color: #2e2e2e;
    border-style: solid;
}

.darkTheme ::selection,
.darkTheme ::-moz-selection {
    color: white;
    background: #2e2e2e;
}